import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {Icon, useQueryParams} from 'shared/helpers';
import {useProductContext} from 'contexts';

interface SearchProps {
    isMiniBrowser?: boolean;
}

export const Search = ({isMiniBrowser}: SearchProps) => {
    const {rails} = useQueryParams() as {rails: string};
    const [searchLocal, setSearchLocal] = useState('');
    const {search, setSearch, breadcrumbs, removeBreadCrumb} =
        useProductContext<{
            search: string;
            setSearch: (search: string) => void;
            breadcrumbs: {key: string}[];
            removeBreadCrumb: (key: string) => void;
        }>();

    const searchKeyHandler = useCallback(() => {
        setSearch(searchLocal);
    }, [searchLocal]);

    const searchKeyInputHandler = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
                // i.e. user hits enter key
                searchKeyHandler();
            }
        },
        [searchKeyHandler]
    );

    const formatSearchKey = useCallback(
        (key: string) =>
            key
                .split(' ')
                .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
                .join(''),
        []
    );

    const clearSearch = useCallback(() => {
        setSearchLocal('');
        setSearch('');
    }, []);

    const changeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchLocal(event.target.value);
        },
        []
    );

    const handleRemoveBreadcrumb = useCallback(
        (searchKey: string) => () => {
            removeBreadCrumb(searchKey);
        },
        [removeBreadCrumb]
    );

    useEffect(() => {
        if (rails) {
            setSearch('rail');
        }
    }, [rails]);

    useEffect(() => {
        setSearchLocal(search);
    }, [search]);

    return (
        <>
            <div className="searchKeysNInput">
                {!isMiniBrowser
                    ? breadcrumbs.map((searchItem, index) => {
                          if (breadcrumbs.length - 1 === index) {
                              return (
                                  <div
                                      className="searchKey hasHandler"
                                      key={searchItem.key}>
                                      <div>
                                          {formatSearchKey(searchItem.key)}
                                      </div>
                                      <div
                                          onClick={handleRemoveBreadcrumb(
                                              searchItem.key
                                          )}>
                                          &times;
                                      </div>
                                  </div>
                              );
                          }

                          return (
                              <div className="searchKey" key={searchItem.key}>
                                  {formatSearchKey(searchItem.key)}
                              </div>
                          );
                      })
                    : null}

                {search && search.length ? (
                    <div className="searchKey hasHandler">
                        <div>{search}</div>
                        <div onClick={clearSearch}>&times;</div>
                    </div>
                ) : (
                    <Form.Control
                        as="input"
                        className="searchField"
                        onKeyUp={searchKeyInputHandler}
                        value={searchLocal}
                        onChange={changeHandler}
                    />
                )}
            </div>
            <Button
                variant="link"
                type="button"
                className="searchButton"
                onClick={searchKeyHandler}>
                <Icon iconName="search-black.svg" />
            </Button>
        </>
    );
};
