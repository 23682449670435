import React, {useEffect, useState} from 'react';
import {BaseConfig} from 'config';
import {JobMenuItem} from 'shared/components/Header/JobMenuItem';
import {useAppContext} from 'contexts';
import {urlPartsJob} from 'shared/helpers';
import {useSearchParams} from 'react-router-dom';

export const MobileMenu = () => {
    const {userLevel, isMobileMenuVisible} = useAppContext();
    const [jobMenu, setJobMenu] = useState([]);
    const [jobId] = urlPartsJob();
    const [searchParams] = useSearchParams();
    const productId = searchParams.get('product');

    const menuToggleEvent = (event, reset = false) => {
        const mobileMenu = document.querySelector('#mobile-menu');

        if (reset) {
            const mainContent = document.querySelector('.mainContent');

            if (mainContent) {
                mainContent.style.paddingBottom = `0px`;
                mainContent.classList.remove('mobile');
            }

            return;
        }

        if (mobileMenu) {
            const mobileMenuRect = mobileMenu.getBoundingClientRect();

            document.querySelector(
                '.mainContent'
            ).style.paddingBottom = `${mobileMenuRect.height}px`;
            document.querySelector('.mainContent').classList.add('mobile');
        }
    };

    useEffect(() => {
        window.addEventListener('onMobileMenuUpdate', menuToggleEvent);

        return () => {
            window.removeEventListener('onMobileMenuUpdate', menuToggleEvent);
            menuToggleEvent(null, true);
        };
    }, []);

    useEffect(() => {
        if (
            userLevel === 'USER_CUSTOMER' &&
            typeof BaseConfig[userLevel] !== 'undefined'
        ) {
            let menu = [...BaseConfig[userLevel]['jobMenu']];

            menu = menu.filter(
                (menuItem) =>
                    !menuItem.hasOwnProperty('isVisibleMobile') ||
                    (menuItem.hasOwnProperty('isVisibleMobile') &&
                        menuItem.isVisibleMobile)
            );

            menu.sort(
                (first, second) => first.orderMobile - second.orderMobile
            );

            setJobMenu(menu);
        } else {
            setJobMenu([]);
        }
    }, [userLevel]);

    if (jobId && isMobileMenuVisible) {
        return (
            <div className="mobile-menu" id="mobile-menu">
                <JobMenuItem jobMenu={jobMenu} isCollapsed={false} />
            </div>
        );
    }

    return <></>;
};
