import React, {useCallback, useState} from 'react';
import {FloatingButton} from 'shared/components/Header/RoomPlanner/FloatingButton';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';

interface CollapsibleButtonProps {
    icon: string;
    children?: React.ReactNode;
    className?: string;
    defaultShow?: boolean;
}

export const CollapsibleButton = ({
    icon,
    children,
    className,
    defaultShow = false,
}: CollapsibleButtonProps) => {
    const [show, setShow] = useState(defaultShow);

    const clickHandler = useCallback(() => {
        setShow((show) => !show);
    }, []);

    return (
        <Container>
            <FloatingButton
                icon={icon}
                onClick={clickHandler}
                className={className}
            />

            <SubMenu $show={show}>{children}</SubMenu>
        </Container>
    );
};

const Container = styled.div`
    margin: 5px 0;
    position: relative;
`;

const SubMenu = styled.div<{$show: boolean}>`
    position: absolute;
    top: 0px;
    right: 42px;
    bottom: 0;
    margin: 3px 0 8px;
    display: ${({$show}) => ($show ? 'flex' : 'none')};
    padding: 0 10px;
    align-items: center;
    gap: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.secondary.main};
    border-right: 0;
    z-index: -1;
    background: white;
`;

export const SubMenuItem = styled(Icon)`
    width: 25px;
    height: 25px;
    cursor: pointer;

    &:active {
        transform: scale(0.95);
    }
`;
