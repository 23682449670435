import {useCallback, useEffect, useState} from 'react';
import {getInfo, saveInfo} from 'service';
import {useNotificationContext, useProductContext} from 'contexts';
import {
    productMaxDimensions,
    getFieldValue,
    genericMessageHandler,
} from 'shared/helpers';
import {Product} from 'components/customer/Product/entity/Product';
import {User} from 'components/customer/Auth/store/userSlice';

export interface MoreInfoScope {
    rangehood: number;
}

export const useMoreInfo = (
    info: number | string,
    scope: MoreInfoScope,
    userProfile: User,
    handleClose: () => void
) => {
    const {notify} = useNotificationContext();
    const [infoText, setInfoText] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [editInfo, setEditInfo] = useState(false);
    const [newInfoOnSave, setNewInfoOnSave] = useState('');

    const productData: {
        productDataStore: {
            current: Partial<Product>;
        };
    } = useProductContext();

    const saveMoreInfo = useCallback(() => {
        saveInfo(Number(info), newInfoOnSave)
            .then(() => {
                setInfoText(newInfoOnSave);
                handleClose();
            })
            .catch(() => {
                genericMessageHandler(notify, {
                    message: 'Could not save more info. Please try again later',
                });
            });
    }, [info, newInfoOnSave, handleClose]);

    useEffect(() => {
        if (typeof info === 'number') {
            setLoading(true);

            getInfo(info)
                .then((infoText) => {
                    if (/\{.*\}/.test(infoText) && productData) {
                        infoText = productMaxDimensions(
                            productData.productDataStore.current,
                            infoText,
                            userProfile,
                            scope
                        ) as string;
                    } else {
                        infoText = getFieldValue(
                            infoText.replace(/\$/g, ''),
                            scope,
                            userProfile
                        );
                    }

                    setInfoText(infoText);
                })
                .catch(() => {
                    genericMessageHandler(notify, {
                        message:
                            'Could not fetch more info. Please try again later',
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setInfoText(info);
        }
    }, [info]);

    return {
        infoText,
        loading,
        editInfo,
        setEditInfo,
        newInfoOnSave,
        setNewInfoOnSave,
        saveMoreInfo,
    };
};
