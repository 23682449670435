import React, {useCallback} from 'react';
import {CollapsibleButton} from 'shared/components/Header/RoomPlanner/CollapsibleButton';
import styled from 'styled-components';
import {ButtonWithSwitch} from 'shared/components/Header/RoomPlanner/ButtonWithSwitch';
import {
    setShowArrows,
    setShowCabinets,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import {useAppDispatch} from 'store/customer';

export const PreviewButton = () => {
    const dispatch = useAppDispatch();

    const handleShowArrows = useCallback(
        (isOn: boolean) => {
            dispatch(setShowArrows(isOn));
        },
        [dispatch]
    );

    const handleShowCabinets = useCallback(
        (isOn: boolean) => {
            dispatch(setShowCabinets(isOn));
        },
        [dispatch]
    );

    return (
        <TallCollabsibleButton icon="Visible.svg">
            <ButtonWithSwitch
                icon="Product.svg"
                defaultChecked={true}
                onToggle={handleShowCabinets}
            />
            {/* <ButtonWithSwitch icon="Oven-flat.svg" defaultChecked={true} /> */}
            {/* <ButtonWithSwitch icon="Window.svg" /> */}
            {/* <ButtonWithSwitch icon="Furninture.svg" /> */}
            <ButtonWithSwitch
                icon="Measurements.svg"
                onToggle={handleShowArrows}
            />
        </TallCollabsibleButton>
    );
};

const TallCollabsibleButton = styled(CollapsibleButton)`
    height: 65px;
`;
