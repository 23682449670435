import React, {useCallback} from 'react';
import {useRoomPlannerLeftMenu} from 'components/customer/RoomPlanner/helper/useRoomPlannerLeftMenu';
import {FloatingButton} from 'shared/components/Header/RoomPlanner/FloatingButton';
import styled from 'styled-components';
import {ZoomControls} from 'shared/components/Header/RoomPlanner/ZoomControls';
import {ViewButton} from 'shared/components/Header/RoomPlanner/ViewButton';
import {PreviewButton} from 'shared/components/Header/RoomPlanner/PreviewButton';
import {useJobContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {
    getIsWireframeMode,
    setIsWireframeMode,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';
import {useAppDispatch, useAppSelector} from 'store/customer';

export const FloatingButtons = () => {
    const {isRoomPlannerPage} = useRoomPlannerLeftMenu();
    const jobContext = useJobContext() as PartialJob;
    const dispatch = useAppDispatch();
    const isWireframeMode = useAppSelector(getIsWireframeMode);

    const pdfReport = useCallback(() => {
        if (jobContext?.job) {
            window.open(`/api/job/${jobContext.job.id}/pdf/details`, '_blank');
        }
    }, [jobContext?.job]);

    const toggleWireframe = useCallback(() => {
        dispatch(setIsWireframeMode(!isWireframeMode));
    }, [isWireframeMode, dispatch]);

    if (isRoomPlannerPage) {
        return (
            <div style={{position: 'relative'}}>
                <Container>
                    <ButtonSection>
                        <FloatingButton icon="PDF.svg" onClick={pdfReport} />
                        <PreviewButton />
                    </ButtonSection>
                    <ButtonSection>
                        <FloatingButton
                            onClick={toggleWireframe}
                            icon={
                                isWireframeMode
                                    ? 'preview/Solid.svg'
                                    : 'preview/Wireframe.svg'
                            }
                        />
                        <ViewButton />
                        <ZoomControls />
                    </ButtonSection>
                </Container>
            </div>
        );
    }
};

const ButtonSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    position: fixed;
    width: 80px;
    right: 0;
    top: 70px;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px 0;
`;
