import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Nav, Col, Row} from 'react-bootstrap';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';
import {WizardNavLink} from './WizardNavLink';

import './Wizard.scss';
import styled from 'styled-components';

export const Wizard = ({
    children,
    currentTab,
    setCurrentTab,
    tabErrors,
    id,
    className,
    imageColumn,
    lazy = false,
    inSizesTab = false,
    wizardHeaderRef = null,
    isMiniBrowser = false,
    showNav,
}) => {
    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();
    const [selectedTitle, setSelectedTitle] = useState('');

    const filterChildren = useCallback((child) => {
        if (child.props.hasOwnProperty('hide') && child.props.hide) {
            return false;
        }

        if (
            child.props.hasOwnProperty('visibility') &&
            !child.props.visibility
        ) {
            return false;
        }

        return true;
    }, []);

    const setRef = useCallback((el: HTMLDivElement) => {
        if (wizardHeaderRef && el) {
            wizardHeaderRef.current = el;
        }
    }, []);

    const navigationData = useMemo(() => {
        return children.filter(filterChildren).map((child, index) => {
            if (showNav && !showNav.includes(index)) {
                return null;
            }

            return {
                eventKey: child.props.eventKey,
                title: child.props.title,
                hasPadding: child.props.hasOwnProperty('noPadding')
                    ? !child.props.noPadding
                    : true,
            };
        });
    }, [children, showNav]);

    const tabClick = useCallback((key) => {
        setCurrentTab(parseInt(key));
    }, []);

    useEffect(() => {
        const currentSection = navigationData.find(
            (section) => section?.eventKey == currentTab
        );

        if (currentSection) {
            setSelectedTitle(currentSection.title);
        }
    }, [currentTab]);

    const [navigation, hasPadding, title] = useMemo(() => {
        {
            let hasPadding = true;
            let navigation = null;
            let title = '';

            if (navigationData.filter(Boolean).length === 0) {
                return [null, false, title];
            }

            if (!isSmallDevice) {
                navigation = (
                    <Nav
                        style={isMiniBrowser ? {padding: '0 12px'} : {}}
                        fill
                        className="cbc-wizard"
                        id={id}>
                        {navigationData.map((child, index) => {
                            if (child === null) return child;

                            const errors = tabErrors && tabErrors[index];

                            if (currentTab == child.eventKey) {
                                hasPadding = child.hasPadding;
                                title = child.title;
                            }

                            return (
                                <WizardNavLink
                                    currentTab={currentTab}
                                    key={child.title}
                                    errors={errors}
                                    section={child}
                                    showIcon={
                                        navigationData.length > 3 &&
                                        isMiniBrowser
                                    }
                                    tabClick={tabClick}
                                />
                            );
                        })}
                    </Nav>
                );
            } else {
                const currentChild = navigationData.find(
                    (navigationDatum) => navigationDatum.eventKey == currentTab
                );

                if (currentChild) {
                    hasPadding = currentChild.hasPadding;
                    title = currentChild.title;
                    navigation = (
                        <div className="cbc-wizard-title" ref={setRef}>
                            <span>{currentChild.title}</span>
                        </div>
                    );
                }
            }

            return [navigation, hasPadding, title];
        }
    }, [navigationData, isSmallDevice, tabClick, currentTab, tabErrors]);

    const showDynamicColumns = (inSizesTab) => {
        const filteredChildren = children
            .filter(filterChildren)
            .filter((child) => {
                if (lazy && child.props.eventKey != currentTab) {
                    return false;
                }
                return true;
            });

        const displayChild = (child, index) => {
            const display = child.props.eventKey == currentTab;
            return React.cloneElement(child, {
                props: child.props,
                className: !display ? 'section-hidden' : '',
            });
        };

        if (inSizesTab === true) {
            return (
                <Row>
                    {imageColumn ? (
                        <Col
                            md={isTabletSize ? 4 : 3}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}>
                            {imageColumn}
                        </Col>
                    ) : null}
                    <Col
                        md={imageColumn ? (isTabletSize ? 8 : 9) : 12}
                        style={
                            isTabletSize
                                ? {paddingLeft: 0, paddingRight: 0}
                                : {}
                        }>
                        {filteredChildren.map(displayChild)}
                    </Col>
                </Row>
            );
        } else {
            return <>{filteredChildren.map(displayChild)}</>;
        }
    };

    return (
        <>
            {navigation}
            {navigationData.length > 3 && isMiniBrowser && selectedTitle ? (
                <Header>{selectedTitle}</Header>
            ) : null}
            <WizardBody
                $noPadding={typeof showNav !== 'undefined'}
                style={hasPadding ? {} : {paddingLeft: 0, paddingRight: 0}}
                className={
                    typeof className !== 'undefined'
                        ? `cbc-wizard-content ${className} ${title}`
                        : `cbc-wizard-content ${title}`
                }>
                {showDynamicColumns(inSizesTab)}
            </WizardBody>
        </>
    );
};

const WizardBody = styled.div`
    ${({$noPadding}) => {
        if ($noPadding) {
            return `padding: 0 !important;`;
        }
    }}
`;

const Header = styled.h1`
    margin: 15px 12px 0 !important;
    padding: 2px 10px !important;
`;
