import React, {useState} from 'react';
import {Image} from 'react-bootstrap';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {ItemColumn} from 'shared/components/Product/ItemColumn';
import styled from 'styled-components';

export interface CategoryInterface {
    id: number;
    name: string;
    imageUrl: string;
    action: () => void;
    noImage?: boolean;
}

interface CategoryProps {
    category: CategoryInterface;
    categoryClickHandler: (category: unknown) => void;
    isProduct: boolean;
    containerLength: number;
    isMiniBrowser?: boolean;
}

export const Category = ({
    category,
    categoryClickHandler,
    isProduct,
    containerLength,
    isMiniBrowser = false,
}: CategoryProps) => {
    const [imageFailed, setImageFailed] = useState(true);
    const [imageLoading, setImageLoading] = useState(true);

    const handleClick = () => {
        if (category.hasOwnProperty('noImage') && category.noImage) {
            category.action();
        } else {
            categoryClickHandler(category);
        }
    };

    const imageContainerStyle = isProduct ? {background: '#fff'} : {};

    return (
        <ItemColumn $container={containerLength}>
            <div
                className={
                    (category.hasOwnProperty('noImage') && category.noImage) ||
                    imageFailed
                        ? 'hardwareCategory no-image'
                        : 'hardwareCategory'
                }
                onClick={handleClick}>
                <ImageContainer
                    $isMiniBrowser={isMiniBrowser}
                    className="imageContainer"
                    style={imageContainerStyle}>
                    {imageLoading && (
                        <div className="hardwareCategory">
                            <div className="animated-background-for-image" />
                        </div>
                    )}

                    <Image
                        src={`/${category.imageUrl}`}
                        alt={category.name}
                        style={
                            isProduct
                                ? {
                                      objectFit: 'contain',
                                      padding: '5px',
                                  }
                                : {
                                      objectFit: 'cover',
                                  }
                        }
                        onError={() => {
                            setImageLoading(false);
                        }}
                        onLoad={() => {
                            setImageFailed(false);
                            setImageLoading(false);
                        }}
                    />
                </ImageContainer>
                <CategoryName
                    $noImage={imageFailed}
                    $isMiniBrowser={isMiniBrowser}
                    className="categoryName">
                    {parseHtmlString(category.name)}
                </CategoryName>
            </div>
        </ItemColumn>
    );
};

export const ImageContainer = styled.div<{$isMiniBrowser: boolean}>`
    ${({$isMiniBrowser}) => {
        if ($isMiniBrowser) {
            return 'height: 100px !important;';
        }
    }}
`;

export const CategoryName = styled.div<{
    $isMiniBrowser: boolean;
    $noImage: boolean;
}>`
    ${({$isMiniBrowser, $noImage}) => {
        if ($isMiniBrowser && $noImage) {
            return 'height: 134px !important;';
        }
    }}
`;
