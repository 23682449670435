import React, {ReactElement, useCallback} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Icon} from 'shared/helpers';
import {
    Category,
    CategoryInterface,
    ImageContainer,
} from 'shared/components/Product/Category';
import {ItemColumn, useItemColumn} from 'shared/components/Product/ItemColumn';
import styled from 'styled-components';
import {useProductContext} from 'contexts';

type CategoriesType = {
    isProduct: boolean;
    categories: CategoryInterface[];
    loader: boolean;
};

export const Categories = ({
    isProduct,
    categories,
    loader,
}: CategoriesType): ReactElement => {
    const {roomId} = useParams();
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category') || false;
    const subCategory = searchParams.get('subCategory') || false;
    const {containerLength, resizeRef, loaders} = useItemColumn(loader);
    const {isMiniBrowser} = useProductContext<{isMiniBrowser: boolean}>();

    const navigate = useNavigate();

    const favouriteClickHandler = useCallback(() => {
        navigate(`?favourites=1`);
    }, [navigate]);

    const categoryClickHandler = useCallback(
        ({id, isSubCategory}: {id: string; isSubCategory: boolean}) => {
            const searchParams = new URLSearchParams(location.search);

            if (roomId) {
                if (isSubCategory) {
                    searchParams.set('category', String(category));
                    searchParams.set('subCategory', id);
                } else {
                    searchParams.set('category', id);
                    searchParams.delete('subCategory');
                }
            } else {
                searchParams.set('category', id);
            }

            navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        },
        [roomId, category, location.search, location.pathname, navigate]
    );

    return (
        <CategoryContainer className="hardwareCategories" ref={resizeRef}>
            {loader ? (
                <LoaderContainer className="hardwareItems">
                    {loaders}
                </LoaderContainer>
            ) : null}
            {loader || category || subCategory ? null : (
                <ItemColumn
                    $container={containerLength}
                    className="hardwareCategory"
                    onClick={favouriteClickHandler}>
                    <ImageContainer
                        $isMiniBrowser={isMiniBrowser}
                        className="imageContainer favourite-container">
                        <Icon
                            className="favourite"
                            iconName="Favourite-item-selected.svg"
                        />
                    </ImageContainer>
                    <div className="categoryName">Favourites</div>
                </ItemColumn>
            )}
            {!loader
                ? categories.map((category) => {
                      return (
                          <Category
                              isMiniBrowser={isMiniBrowser}
                              category={category}
                              categoryClickHandler={categoryClickHandler}
                              key={`${category.id}-${category.name}`}
                              isProduct={isProduct}
                              containerLength={containerLength}
                          />
                      );
                  })
                : null}
        </CategoryContainer>
    );
};

const CategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
    align-items: flex-start;
    justify-content: flex-start;
`;

const LoaderContainer = styled.div`
    display: flex;
    gap: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
`;
