import React, {useCallback, useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import {
    useNotificationContext,
    useProductContext,
    useAppContext,
} from 'contexts';
import {OverlayTrigger} from 'shared';
import {validationErrorAppliesToField} from 'shared/validator';
import {mapErrors} from 'shared/helpers/mapErrors';
import {Structure} from 'components/customer/Product/entity/Structure';
import {Message} from 'contexts/NotificationContext';

interface FormControlProps {
    name: string;
    value: string | number;
    selectHandler?: (name: string, value: string | number) => void;
    type?: string;
    hasFormik?: boolean;
    isInvalid?: boolean;
    disabled?: boolean;
    isQFP?: boolean;
    randomId?: boolean;
    fieldIndex?: number;
    fieldSetIndex?: number;
    wholeNumber?: boolean;
    preventMinInput?: boolean;
    autoFocus?: boolean;
    showDialog?: boolean;
    setCurrentTab?: (tab: string) => void;
    formStructure?: Structure;
    fieldSetGroupName?: string;
    as?: 'input' | 'textarea' | 'select';
    min?: number;
    field?: {
        blurAction?: () => void;
        focusAction?: () => void;
    };
    inSpecsTab?: boolean;
    in_sizes_tab?: boolean;
}

export const FormControl = ({
    name,
    value,
    selectHandler = undefined,
    type = 'text',
    hasFormik = true,
    isInvalid = false,
    disabled = false,
    isQFP = false,
    fieldIndex = undefined,
    fieldSetIndex = undefined,
    wholeNumber = false,
    preventMinInput = true,
    autoFocus = false,
    field = undefined,
    ...otherOptions
}: FormControlProps) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // basically extracting the props that are not needed in the form control
    // form control throws error if these are passed in
    const {
        randomId,
        showDialog,
        setCurrentTab,
        formStructure,
        fieldSetGroupName,
        inSpecsTab,
        in_sizes_tab: inSizesTab,
        ...innerComponentProps
    } = otherOptions;
    // We just need to extract the error from the useField hook
    const [_, {error}] = hasFormik
        ? useField({name, type, multiple: true})
        : [undefined, {error: undefined}];
    /* eslint-disable @typescript-eslint/no-unused-vars */

    const [localValue, setLocalValue] = useState(value);
    const formikContext = hasFormik ? useFormikContext() : false;
    const {messages} = useNotificationContext();
    const [tooltip, setTooltip] = useState<
        string | JSX.Element | JSX.Element[]
    >();
    const productSpecificData: {fieldIndex: number} = useProductContext();
    const {smallLayout} = useAppContext();
    const [fieldIsInvalid, setFieldIsInvalid] = useState(isInvalid);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [fieldUpdating, setFieldUpdating] = useState(false);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const updateValue = useCallback(
        (newValue: typeof value) => {
            if (typeof selectHandler !== 'undefined' && selectHandler) {
                selectHandler(name, newValue);
            } else {
                if (formikContext) {
                    void formikContext.setFieldValue(name, newValue);
                }
            }
        },
        [selectHandler, formikContext, name]
    );

    useEffect(() => {
        if (isQFP) {
            if (error) {
                setTooltip(error);
                setFieldIsInvalid(true);
            } else {
                if (fieldIndex >= 0 && fieldSetIndex >= 0 && isInvalid) {
                    // fieldIndex is row index. i.e. index of the each qfp product
                    // fieldSetIndex is still the index of the particular fieldset

                    const errorMessages = messages.errors;
                    const productErrors = errorMessages[Number(fieldIndex)];

                    if (productErrors && Array.isArray(productErrors)) {
                        const tooltips = productErrors
                            .filter((error) =>
                                validationErrorAppliesToField(
                                    error,
                                    name,
                                    fieldSetIndex
                                )
                            )
                            .map((error) => error.message);

                        if (tooltips.length > 0) {
                            setTooltip(mapErrors(tooltips));
                            setFieldIsInvalid(true);
                        }
                    }
                }
                setFieldIsInvalid(isInvalid);
            }
        }
    }, [error, isInvalid, messages]);

    useEffect(() => {
        if (!isQFP) {
            let localMessages: Message[] = [];
            let index = fieldSetIndex;

            if (
                productSpecificData &&
                productSpecificData.hasOwnProperty('fieldIndex') &&
                productSpecificData.fieldIndex > -1
            ) {
                index = productSpecificData.fieldIndex;
            }

            if (Array.isArray(messages.errors)) {
                localMessages = messages.errors;
            } else if (
                typeof index === 'number' &&
                index >= 0 &&
                messages.errors.hasOwnProperty(index)
            ) {
                const tempMessages = messages.errors[Number(index)];

                if (Array.isArray(tempMessages)) {
                    localMessages = tempMessages;
                } else {
                    localMessages = [tempMessages];
                }
            }

            if (isInvalid) {
                const toolTips = localMessages
                    .filter((error) =>
                        validationErrorAppliesToField(error, name, index)
                    )
                    .map((error) => error.message);

                setTooltip(mapErrors(toolTips, smallLayout));
                setFieldIsInvalid(true);
            } else {
                setFieldIsInvalid(false);
            }
        }
    }, [messages, isInvalid, smallLayout]);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    useEffect(() => {
        // this effect ensures field are sync before form submission
        if (value !== localValue) {
            setFieldUpdating(true);
        } else {
            setFieldUpdating(false);
        }
    }, [value, localValue]);

    useEffect(() => {
        // this effect will trigger form submission once field values are sync and form is submitted via enter key
        if (!fieldUpdating && formSubmitted) {
            if (formikContext) {
                void formikContext.submitForm();
            }
            setFormSubmitted(false);
        }
    }, [fieldUpdating, formSubmitted]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;
            const targetValue = isIOS ? parseFloat(inputValue) : inputValue;

            if (wholeNumber && Math.floor(Number(targetValue)) != targetValue) {
                return;
            }

            if (
                preventMinInput &&
                otherOptions.min &&
                otherOptions.min > Number(targetValue)
            ) {
                return;
            }

            setLocalValue(targetValue);

            if (event.target !== document.activeElement) {
                updateValue(targetValue);
            }
        },
        [updateValue, otherOptions, preventMinInput, wholeNumber, setLocalValue]
    );

    const handleBlurCustom = useCallback(
        (event?: React.FocusEvent) => {
            if (
                field &&
                field.hasOwnProperty('blurAction') &&
                typeof field.blurAction === 'function'
            ) {
                field.blurAction();
            }

            if (event) {
                formikContext && formikContext.handleBlur(event);
            }

            if (value !== localValue) {
                let updatedLocalValue = localValue;
                if (type === 'number' && typeof localValue === 'string') {
                    if (localValue.length > 0) {
                        updatedLocalValue =
                            localValue.indexOf('.') >= 0
                                ? parseFloat(localValue)
                                : parseInt(localValue);
                    }
                }

                updateValue(updatedLocalValue);
            }
        },
        [localValue, formikContext, updateValue]
    );

    const handleFocusCustom = useCallback(() => {
        if (
            field &&
            field.hasOwnProperty('focusAction') &&
            typeof field.focusAction === 'function'
        ) {
            field.focusAction();
        }
    }, [field]);

    const enterKeyPressed = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.keyCode === 13) {
                if (
                    otherOptions &&
                    otherOptions.hasOwnProperty('as') &&
                    otherOptions.as == 'textarea'
                ) {
                    return;
                }
                handleBlurCustom();
                setFormSubmitted(true);
            }
        },
        [otherOptions, setFormSubmitted, handleBlurCustom]
    );

    const formControl = (
        <Form.Control
            {...innerComponentProps}
            autoFocus={autoFocus}
            disabled={disabled}
            type={type}
            name={name}
            value={localValue == null ? '' : localValue}
            onChange={handleChange}
            onKeyUp={enterKeyPressed}
            onBlur={handleBlurCustom}
            onFocus={handleFocusCustom}
            isInvalid={fieldIsInvalid}
            data-cy={`${name}${
                !isNaN(fieldSetIndex) ? `-${fieldSetIndex + 1}` : ''
            }`}
            inputMode={type == 'number' ? 'numeric' : undefined}
        />
    );

    if (fieldIsInvalid && typeof tooltip !== 'undefined' && tooltip != '') {
        return (
            <OverlayTrigger
                className="error-popover"
                overlay={tooltip}
                placement={isQFP ? 'auto-start' : 'right-end'}>
                <div style={{flex: 1}}>{formControl}</div>
            </OverlayTrigger>
        );
    }

    return formControl;
};
