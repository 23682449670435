import {HardwareOption} from 'shared/types/HardwareOption';
import React, {useEffect, useMemo, useState} from 'react';
import {usePromise} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

import {DynamicRadioMobile, Radio} from 'shared';

export const DynamicRadio = ({
    name,
    value,
    setFieldValue,
    options,
    disabled = false,
    isQFP = false,
    showDisabledMessage,
    yesOptionHidden,
    noOptionHidden,
}: {
    name: string;
    value: boolean;
    setFieldValue: (n: string, v: boolean) => void;
    options: () => Promise<HardwareOption[]>;
    disabled: boolean;
    isQFP: boolean;
    showDisabledMessage?: () => void;
    yesOptionHidden?: boolean;
    noOptionHidden?: boolean;
}) => {
    const [finalOptions, setFinalOptions] = useState<HardwareOption[]>([]);
    const isSmallDevice = isDeviceSmall();

    useEffect(
        () =>
            usePromise(
                ([data]: [HardwareOption[]]) => {
                    setFinalOptions(data);
                },
                [options()]
            ),
        [options]
    );

    const [yesOption, noOption] = useMemo(() => {
        const finalYesOption = finalOptions.find((v) => v.value == 1);
        const finalNoOption = finalOptions.find((v) => v.value == 0);
        if (yesOptionHidden && finalYesOption) {
            finalYesOption.hidden = true;
        }
        if (noOptionHidden && finalNoOption) {
            finalNoOption.hidden = true;
        }
        return [finalYesOption, finalNoOption];
    }, [finalOptions, yesOptionHidden, noOptionHidden]);

    if (typeof yesOption == 'undefined' || typeof noOption == 'undefined') {
        return null;
    }

    return isSmallDevice || !isQFP ? (
        <DynamicRadioMobile
            {...{
                name,
                value,
                disabled,
                yesOption,
                noOption,
                finalOptions,
                setFieldValue,
                showDisabledMessage,
            }}
        />
    ) : (
        <Radio
            name={name}
            value={value}
            setFieldValue={setFieldValue}
            disabled={disabled}
            type="radio"
            asRow={true}
            options={[
                {
                    value: '1',
                    label: yesOption.name,
                    checked: value,
                    image: yesOption.image,
                    description: yesOption.description,
                },
                {
                    value: '0',
                    label: noOption.name,
                    checked: !value,
                    image: noOption.image,
                    description: noOption.description,
                },
            ]}
        />
    );
};

const hasDrawerText = (displayName: string | null) =>
    displayName?.toLowerCase().indexOf('drawer') > -1;
const hasDoorText = (displayName: string | null) =>
    displayName?.toLowerCase().indexOf('door') > -1;

export const getIncludeDoorFacesLabel = (
    displayName: string | null
): string => {
    const hasDrawer = hasDrawerText(displayName);
    const hasDoor = hasDoorText(displayName);

    if (hasDrawer && hasDoor) return 'Door and Drawer';

    if (hasDoor) return 'Door';

    return 'Drawer';
};

export const getIncludeDoorFacesImage = (
    displayName: string | null
): {yesOptionImage: string; noOptionImage: string} => {
    const hasDrawer = hasDrawerText(displayName);
    const hasDoor = hasDoorText(displayName);

    if (hasDrawer && !hasDoor)
        return {
            yesOptionImage: 'DrawerFaces.svg',
            noOptionImage: 'NoDrawerFaces.svg',
        };

    return {
        yesOptionImage: 'DoorFaces.svg',
        noOptionImage: 'NoDoorFaces.svg',
    };
};
