import React, {useCallback} from 'react';
import {FloatingButton} from 'shared/components/Header/RoomPlanner/FloatingButton';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    setZoom,
    getZoom,
} from 'components/customer/RoomPlanner/store/roomPlannerSlice';

export const ZoomControls = () => {
    const dispatch = useAppDispatch();
    const zoom = useAppSelector(getZoom);

    const handleZoomIn = useCallback(() => {
        dispatch(setZoom(zoom + 0.1));
    }, [dispatch, zoom]);

    const handleZoomOut = useCallback(() => {
        dispatch(setZoom(zoom - 0.1));
    }, [dispatch, zoom]);

    return (
        <Container>
            <FloatingButton icon="Plus-secondary.svg" onClick={handleZoomIn} />
            <FloatingButton
                icon="Minus-secondary.svg"
                onClick={handleZoomOut}
            />
        </Container>
    );
};

const Container = styled.div`
    margin-top: 5px;
    box-shadow: 0px 1px 9px 3px #bfbfbf;
    width: 42px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background: #d1d1d1;

    > button {
        margin: 0;
        box-shadow: none;
    }

    > button:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    > button:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
`;
