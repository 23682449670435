import {HardwareOption} from 'shared/types/HardwareOption';
import React, {useCallback, useState} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {Loader} from 'shared/helpers';
import {RadioCard} from 'shared/components/Forms/RadioCard';
import {Flex} from 'shared/components/StyledComponents';
import useDoorFacesHelper from 'components/customer/Materials/helper/useDoorFacesHelper';

export const DynamicRadioMobile = ({
    name,
    value,
    disabled = false,
    yesOption,
    noOption,
    setFieldValue,
    showDisabledMessage,
    finalOptions,
}: {
    name: string;
    value: boolean;
    disabled: boolean;
    yesOption: HardwareOption;
    noOption: HardwareOption;
    setFieldValue: (n: string, v: boolean) => void;
    showDisabledMessage: () => void;
    finalOptions: HardwareOption[];
}) => {
    const isSmallDevice = isDeviceSmall();
    const [isLoading, setIsLoading] = useState(false);
    const {handleDoorFaceRadioChange} = useDoorFacesHelper(setFieldValue);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsLoading(true);
            const targetValue = event.target.value == '1';
            setFieldValue(name, targetValue);
            if (finalOptions.some((o) => o.doorFaces)) {
                handleDoorFaceRadioChange(targetValue)
                    .catch(() => {
                        // console.error('An error occurred:', e);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
                return;
            } else {
                setIsLoading(false);
            }
        },
        [name, finalOptions, setFieldValue, handleDoorFaceRadioChange]
    );

    const handleCheck = useCallback(
        (name: string) => {
            const element = document.getElementById(name);
            if (element) {
                element.click();
            }
            if (disabled && !!showDisabledMessage) {
                showDisabledMessage();
            }
        },
        [showDisabledMessage, disabled]
    );

    const handleChecks = useCallback(
        (checkType: string) => {
            if (!isLoading) {
                handleCheck(`boolean_${name}_${checkType}`);
            }
        },
        [handleCheck, name, isLoading]
    );

    const handleYesCheck = useCallback(
        () => handleChecks('yes'),
        [handleChecks]
    );
    const handleNoCheck = useCallback(() => handleChecks('no'), [handleChecks]);

    return (
        <>
            <Loader loader={isLoading} />
            <Flex $gap="15px">
                {!yesOption.hasOwnProperty('hidden') || !yesOption.hidden ? (
                    <RadioCard
                        type="yes"
                        isSelected={value}
                        handleCheck={handleYesCheck}
                        handleRadioChange={handleChange}
                        disabled={disabled}
                        fieldName={name}
                        option={yesOption}
                        isSmallDevice={isSmallDevice}
                    />
                ) : null}
                {!noOption.hasOwnProperty('hidden') || !noOption.hidden ? (
                    <RadioCard
                        type="no"
                        isSelected={!value}
                        handleCheck={handleNoCheck}
                        handleRadioChange={handleChange}
                        disabled={disabled}
                        fieldName={name}
                        option={noOption}
                        isSmallDevice={isSmallDevice}
                    />
                ) : null}
            </Flex>
        </>
    );
};
