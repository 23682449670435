import React, {useCallback, useState} from 'react';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';

interface ButtonWithSwitchProps {
    icon: string;
    defaultChecked?: boolean;
    onToggle?: (isOn: boolean) => void;
}

export const ButtonWithSwitch = ({
    icon,
    defaultChecked = false,
    onToggle,
}: ButtonWithSwitchProps) => {
    const [isOn, setIsOn] = useState(defaultChecked);

    const toggleSwitch = useCallback(() => {
        onToggle && typeof onToggle === 'function' && onToggle(!isOn);
        setIsOn(!isOn);
    }, [isOn, onToggle]);

    return (
        <SwitchContainer>
            <Image iconName={icon} />
            <SwitchLabel>
                <SwitchInput
                    type="checkbox"
                    checked={isOn}
                    onChange={toggleSwitch}
                />
                <SwitchSlider $isOn={isOn} />
            </SwitchLabel>
        </SwitchContainer>
    );
};

const Image = styled(Icon)`
    width: 40px;
    height: 31px;
`;

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
`;

const SwitchLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 22px;
    height: 13px;
`;

const SwitchInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

const SwitchSlider = styled.span<{$isOn: boolean}>`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({$isOn}) => ($isOn ? '#4caf50' : 'red')};
    transition: 0.4s;
    border-radius: 34px;

    &:before {
        position: absolute;
        content: '';
        height: 9px;
        width: 9px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
        transform: ${({$isOn}) =>
            $isOn ? 'translateX(9px)' : 'translateX(0)'};
    }
`;
