import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {
    ChevronRightWhite,
    ChevronRight,
    ChevronLeftWhite,
    ChevronLeft,
} from 'assets';
import {SIDEBAR} from 'hooks';
import {SidebarStatus, SidebarBehaviour} from 'store/customer/UISlice';

const SidebarToggleStyle = styled.div<{
    $icon: string;
    $position: string;
    $hoverIcon: string;
}>`
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: white;
    right: -12px;
    left: initial;
    top: 58px;
    z-index: 1;
    border-radius: 24px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 2px 2px 6px -1px #707070;
    background-image: url(${(props) => props.$icon});
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: ${(props) => props.$position};

    :hover {
        background-image: url(${(props) => props.$hoverIcon});
        background-color: rgb(var(--primary_colour));
    }
`;

type SidebarToggleType = React.HtmlHTMLAttributes<HTMLDivElement> & {
    direction?: string;
    status: SidebarStatus;
    setStatus: (status: SidebarStatus) => void;
    behaviour: SidebarBehaviour;
};

export const SidebarToggle = ({
    direction = SIDEBAR.LEFT,
    status,
    setStatus,
    behaviour,
    ...props
}: SidebarToggleType) => {
    const [icon, hoverIcon, position] = useMemo(() => {
        let icon = `data:image/svg+xml;base64, ${btoa(String(ChevronRight))}`;
        let hoverIcon = `data:image/svg+xml;base64, ${btoa(
            String(ChevronRightWhite)
        )}`;
        let position = '5px center';

        if (status == SidebarStatus.OPEN) {
            icon = `data:image/svg+xml;base64, ${btoa(String(ChevronLeft))}`;
            hoverIcon = `data:image/svg+xml;base64, ${btoa(
                String(ChevronLeftWhite)
            )}`;
            position = '3px center';
        }

        return [icon, hoverIcon, position];
    }, [status, direction]);

    const statusHandler = useCallback(() => {
        if (behaviour == SidebarBehaviour.AUTOHIDE) {
            setStatus(
                status == SidebarStatus.OPEN
                    ? SidebarStatus.CLOSED
                    : SidebarStatus.OPEN
            );
        }
    }, [status]);

    return (
        <SidebarToggleStyle
            {...props}
            onClick={statusHandler}
            $icon={icon}
            $hoverIcon={hoverIcon}
            $position={position}
        />
    );
};
