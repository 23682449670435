import React, {useCallback} from 'react';
import {
    CollapsibleButton,
    SubMenuItem,
} from 'shared/components/Header/RoomPlanner/CollapsibleButton';
import {useAppDispatch} from 'store/customer';
import {setPreviewPosition} from 'components/customer/RoomPlanner/store/roomPlannerSlice';

export const ViewButton = () => {
    const dispatch = useAppDispatch();

    const setLeftViewPosition = useCallback(() => {
        dispatch(setPreviewPosition('LEFT'));
    }, [dispatch]);

    const setRightViewPosition = useCallback(() => {
        dispatch(setPreviewPosition('RIGHT'));
    }, [dispatch]);

    const setFrontViewPosition = useCallback(() => {
        dispatch(setPreviewPosition('FRONT'));
    }, [dispatch]);

    const setLeftLeveledViewPosition = useCallback(() => {
        dispatch(setPreviewPosition('LEFT_LEVELED'));
    }, [dispatch]);

    const setRightLeveledViewPosition = useCallback(() => {
        dispatch(setPreviewPosition('RIGHT_LEVELED'));
    }, [dispatch]);

    const setTopViewPosition = useCallback(() => {
        dispatch(setPreviewPosition('TOP_WITHOUT_EDITING'));
    }, [dispatch]);

    return (
        <CollapsibleButton icon="View.svg" defaultShow={true}>
            <SubMenuItem
                iconName="preview/Isometric-Left.svg"
                onClick={setLeftViewPosition}
            />
            <SubMenuItem
                iconName="preview/Isometric-Right.svg"
                onClick={setRightViewPosition}
            />
            <SubMenuItem
                iconName="preview/Perspective-Left.svg"
                onClick={setLeftLeveledViewPosition}
            />
            <SubMenuItem
                iconName="preview/Perspective-Right.svg"
                onClick={setRightLeveledViewPosition}
            />
            <SubMenuItem
                iconName="preview/Perspective-Front.svg"
                onClick={setFrontViewPosition}
            />
            <SubMenuItem
                iconName="preview/Perspective-Top.svg"
                onClick={setTopViewPosition}
            />
        </CollapsibleButton>
    );
};
