import {useLayoutEffect, useState} from 'react';
import {
    setIsSmallScreenLayout,
    selectIsSmallScreenLayout,
    SidebarBehaviour,
    setLeftSidebarBehaviour as setLeftSidebarBehaviourAction,
    setRightSidebarBehaviour as setRightSidebarBehaviourAction,
} from 'store/customer/UISlice';
import {useSidebarBehaviour} from 'hooks';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useAppDispatch, useAppSelector} from 'store/customer';

const STANDARD_SIZE = 1440;

const useUI = () => {
    const dispatch = useAppDispatch();
    const isSmallScreenLayout = useAppSelector(selectIsSmallScreenLayout);
    const isSmallDevice = isDeviceSmall();
    const [layoutEffectDone, setLayoutEffectDone] = useState(false);

    useLayoutEffect(() => {
        const availableWidth: number = document.body.clientWidth;

        if (!isSmallDevice && availableWidth <= STANDARD_SIZE) {
            dispatch(setLeftSidebarBehaviourAction(SidebarBehaviour.AUTOHIDE));
            dispatch(setRightSidebarBehaviourAction(SidebarBehaviour.AUTOHIDE));
            dispatch(setIsSmallScreenLayout(true));
        }
        setLayoutEffectDone(true);
    }, [isSmallDevice]);

    const {
        alternateCartContent,
        leftSidebar,
        rightSidebar,
        leftSidebarBehaviour,
        rightSidebarBehaviour,
        setLeftSidebar,
        setRightSidebar,
        setLeftSidebarBehaviour,
        setRightSidebarBehaviour,
        setAlternateCartContent,
    } = useSidebarBehaviour();

    return {
        alternateCartContent,
        leftSidebar,
        rightSidebar,
        leftSidebarBehaviour,
        rightSidebarBehaviour,
        setLeftSidebar,
        setRightSidebar,
        setLeftSidebarBehaviour,
        setRightSidebarBehaviour,
        setAlternateCartContent,
        isSmallScreenLayout,
        layoutEffectDone,
    };
};

export default useUI;
