import React from 'react';
import {Form, Row} from 'react-bootstrap';
import styled from 'styled-components';

interface FormGroupProps {
    children: React.ReactNode;
    controlId?: string;
    style?: React.CSSProperties;
    asRow?: boolean;
    className?: string;
}

export const FormGroup = ({
    controlId,
    children,
    style,
    asRow = true,
    className,
}: FormGroupProps) => {
    // I could not make FormControl as Row work at all.
    // this is a workaround to make it work.
    return (
        <Group className={className} controlId={controlId} style={style}>
            {asRow ? <Row>{children}</Row> : children}
        </Group>
    );
};

const Group = styled(Form.Group)`
    display: flex;
    margin-bottom: 10px;
    gap: 15px 0;

    > div {
        width: 100%;
    }

    label {
        font-size: 0.85em;
        font-weight: bold;
        color: ${({theme}) => theme.colors.primary.main};
        position: relative;

        > span {
            color: ${({theme}) => theme.colors.errorLabel.main};
        }
    }

    input {
        color: #495057 !important;
    }
`;
