import React, {useCallback} from 'react';
import {Button, Nav} from 'react-bootstrap';
import {Navs, getNavIcons} from 'shared/components/Wizard/helpers/getNavIcons';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';

interface Section {
    title: Navs;
    eventKey: number;
}

interface WizardNavLinkProps {
    section: Section;
    showIcon: boolean;
    errors: number;
    tabClick: (eventKey: number) => void;
    className?: string;
    currentTab: number;
}

export const WizardNavLink = ({
    currentTab,
    section,
    showIcon,
    errors,
    tabClick,
    className = '',
}: WizardNavLinkProps) => {
    const handleNavClick = useCallback(() => {
        tabClick(section.eventKey);
    }, [tabClick, section]);

    return (
        <NavLink
            $showIcon={showIcon}
            className={`${className} nav-item ${
                currentTab === section.eventKey ? 'active' : ''
            }`}
            as={Button}
            onClick={handleNavClick}>
            <div className="tab-title" title={section.title}>
                {showIcon ? (
                    <CustomIcon iconName={getNavIcons(section.title)} />
                ) : (
                    section.title
                )}
            </div>
            {errors ? (
                <div className="tab-errors">
                    {errors} error
                    {errors > 1 ? 's' : ''}
                </div>
            ) : null}
        </NavLink>
    );
};

const NavLink = styled(Nav.Link)<{$showIcon: boolean}>`
    ${({$showIcon}) => {
        if ($showIcon) {
            return `
                padding: 0 !important;

                > div {
                    padding: 5px 0;
                }
            `;
        }
    }}
`;

const CustomIcon = styled(Icon)`
    width: 20px;
`;
