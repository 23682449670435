import React from 'react';
import styled, {keyframes} from 'styled-components';

interface ShimmerProps {
    className?: string;
}

export const Shimmer = ({className}: ShimmerProps) => {
    return <ShimmerDiv className={className} />;
};

const placeHolderShimmer = keyframes`
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
`;

const ShimmerDiv = styled.div`
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    width: 100%;
    height: 100%;
`;
