export type Navs =
    | 'Specs'
    | 'Materials'
    | 'Drawers'
    | 'Doors'
    | 'Shelves'
    | 'Faces'
    | 'Edging'
    | 'Drillings';

const NavIcons = new Map<Navs, string>([
    ['Specs', 'Button-Convert-mm.svg'],
    ['Materials', 'Material-White.svg'],
    ['Drawers', 'Headers-Add-Product.svg'],
    ['Doors', 'Header-Doors.svg'],
    ['Shelves', 'Button-Add-Shelf.svg'],
    ['Faces', 'DoorFaces-White.svg'],
    ['Edging', 'DoorFaces-White.svg'],
    ['Drillings', 'Header-Panel.svg'],
]);

export const getNavIcons = (key: string) => {
    if (NavIcons.has(key as Navs)) {
        return NavIcons.get(key as Navs);
    }
};
