import React, {useState, useCallback} from 'react';
import {BaseConfig} from 'config';
import {SidebarToggle} from 'shared';
import {JobMenuItem} from 'shared/components/Header/JobMenuItem';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {SIDEBAR} from 'hooks';
import useUI from 'shared/useUI';
import {SidebarStatus, SidebarBehaviour} from 'store/customer/UISlice';
import Sidebar, {useLeftSidebar} from 'shared/components/Sidebar';
import MenuButton from 'shared/components/MenuButton';
import {VariationResetButton} from 'shared/components/Header/VariationResetButton';
import {SubmitButton} from 'shared/components/Header/SubmitButton';
import {Menu} from 'components/customer/RoomPlanner/components/Menu';
import styled from 'styled-components';
import {useRoomPlannerLeftMenu} from 'components/customer/RoomPlanner/helper/useRoomPlannerLeftMenu';

export const SidebarLeft = () => {
    const isSmallDevice = isDeviceSmall();
    const {
        isSmallScreenLayout,
        leftSidebar,
        leftSidebarBehaviour,
        setLeftSidebar,
    } = useUI();
    const {toggle} = useLeftSidebar();
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

    const {isRoomPlannerPage} = useRoomPlannerLeftMenu();

    const handleNavRefChange = useCallback((ref: HTMLElement) => {
        const navRef = ref;
        if (navRef) {
            const hasVertScroll = navRef.scrollHeight > navRef.clientHeight;

            setHasVerticalScroll(hasVertScroll);
        }
    }, []);

    if (!isSmallDevice) {
        return (
            <Sidebar
                sidebarId="sidebar-left"
                className="sidebar-left"
                anchor="LEFT"
                behaviour={leftSidebarBehaviour}
                status={leftSidebar}
                showOverlays={false} // NOTE: fix this by testing the UI
                partialClose={leftSidebarBehaviour === SidebarBehaviour.TOGGLE}
                partialCloseWidth={80}>
                <div className="sidebar-left-content">
                    <MenuButton status={leftSidebar} onClick={toggle} />
                    <NavContainer
                        // changing this to handleNavRefChange only is behaving incorrectly so disabling it for now
                        // eslint-disable-next-line react/jsx-no-bind
                        ref={(ref) => handleNavRefChange(ref)}
                        className="nav-container"
                        style={
                            isSmallScreenLayout &&
                            leftSidebar === SidebarStatus.CLOSED &&
                            hasVerticalScroll
                                ? {
                                      overflowY: 'hidden',
                                      overflowX: 'hidden',
                                      paddingRight: 10,
                                  }
                                : {
                                      overflowX: 'hidden',
                                  }
                        }>
                        <JobMenu
                            jobMenu={BaseConfig.USER_CUSTOMER.jobMenu}
                            isCollapsed={
                                leftSidebar != SidebarStatus.OPEN &&
                                leftSidebarBehaviour === SidebarBehaviour.TOGGLE
                            }
                            $isRoomPlannerPage={!!isRoomPlannerPage}
                        />
                        {!!isRoomPlannerPage ? (
                            <Menu
                                isCollapsed={
                                    leftSidebar != SidebarStatus.OPEN &&
                                    leftSidebarBehaviour ===
                                        SidebarBehaviour.TOGGLE
                                }
                                menu={BaseConfig.USER_CUSTOMER.roomPlannerMenu}
                            />
                        ) : null}
                        {!!isRoomPlannerPage ? (
                            <Menu
                                isCollapsed={
                                    leftSidebar != SidebarStatus.OPEN &&
                                    leftSidebarBehaviour ===
                                        SidebarBehaviour.TOGGLE
                                }
                                isFooter={true}
                                menu={
                                    BaseConfig.USER_CUSTOMER
                                        .roomPlannerBottomMenu
                                }
                            />
                        ) : null}
                    </NavContainer>
                    <section className="sidebar-footer-left">
                        <SubmitButton
                            left={true}
                            showLabel={leftSidebar === SidebarStatus.OPEN}
                        />
                        <VariationResetButton />
                    </section>
                    {leftSidebarBehaviour == SidebarBehaviour.AUTOHIDE ? (
                        <SidebarToggle
                            className="left-sidebar-toggle"
                            direction={
                                leftSidebar === SidebarStatus.OPEN
                                    ? SIDEBAR.LEFT
                                    : SIDEBAR.RIGHT
                            }
                            status={leftSidebar}
                            setStatus={setLeftSidebar}
                            title="Toggle menu visibility"
                            behaviour={leftSidebarBehaviour}
                        />
                    ) : null}
                </div>
            </Sidebar>
        );
    } else {
        return null;
    }
};

const NavContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2vh 0 0 !important;
`;

const JobMenu = styled(JobMenuItem)<{
    $isRoomPlannerPage: boolean;
}>`
    ${({$isRoomPlannerPage}) => {
        if ($isRoomPlannerPage) {
            return `
                display: none;
            `;
        }
    }}
`;
