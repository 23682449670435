import React from 'react';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';

interface FloatingButtonProps {
    icon: string;
    className?: string;
    onClick?: () => void;
}

export const FloatingButton = ({
    icon,
    className,
    onClick,
}: FloatingButtonProps) => {
    return (
        <Button className={className} onClick={onClick}>
            <Icon iconName={icon} />
        </Button>
    );
};

const Button = styled.button`
    background: white;
    border-radius: 8px;
    box-shadow: 0px 1px 9px 3px #bfbfbf;
    border: 0;
    padding: 6px 9px;
    margin: 5px 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    > img {
        width: 24px;
        height: 24px;
    }

    &:active {
        transform: scale(0.95);
    }
`;
