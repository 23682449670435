import React, {useMemo} from 'react';
import {useJobContext, useAppContext} from 'contexts';
import {PromptButton} from 'shared';
import {JOB_STATUS} from 'components/customer/Dashboard/LastJobButton';
import {useVariationResetButton} from 'shared/components/Header/VariationResetButton';
import {PartialJob} from 'shared/types/PartialJob';
import {useJobSubmitHandler} from 'components/customer/Job/helpers/useJobSubmitHandler';
import {Loader} from 'shared/helpers';
import {useAppSelector} from 'store/customer';
import {getIsSavingProduct} from 'components/customer/Preview3DCommon/store/viewerSlice';
import {shallowEqual} from 'react-redux';

interface SubmitButtonProps {
    left: boolean;
    showLabel: boolean;
}

export const SubmitButton = ({
    left = false,
    showLabel = true,
}: SubmitButtonProps) => {
    const isSavingProduct = useAppSelector(getIsSavingProduct, shallowEqual);
    const {job, isPageDirty, expiredJobPrice, isRoomPlanner} =
        useJobContext() as PartialJob;
    const {handleJobSubmission, dialog, loader} = useJobSubmitHandler();
    const {noVariations, notRemovableExtraVariatons, extraVariation} =
        useVariationResetButton();
    const {userProfile} = useAppContext();

    const submitButtonText = useMemo(() => {
        let buttonText = 'Submit Job';
        if (job.status === JOB_STATUS.JOB_PENDING_CONFIRMATION) {
            if (!noVariations && !extraVariation && job.freightCost == 0) {
                buttonText = 'Confirm Variation Cost';
            } else if (
                noVariations &&
                !extraVariation &&
                job.freightCost == 0
            ) {
                buttonText = 'Approve without Variations';
            } else {
                buttonText = 'Approve Job';
            }
        }

        return buttonText;
    }, [
        job.status,
        noVariations,
        notRemovableExtraVariatons,
        extraVariation,
        job.freightCost,
    ]);

    return (
        <Loader loader={!isRoomPlanner && (loader || isSavingProduct)}>
            {userProfile.inActiveManufacturer == 0 &&
            [0, 11, 19, 5].includes(job.status) &&
            !expiredJobPrice ? (
                <PromptButton
                    disabled={
                        Object.keys(job).length == 0 ||
                        job.totalProductCount == 0
                    }
                    iconName={left ? 'Submit.svg' : 'Button-Submit.svg'}
                    action={handleJobSubmission}
                    showConfirmation={isPageDirty.length > 0}
                    className={
                        left ? 'submit-button-left' : 'button-blue btn-sm'
                    }
                    title={left ? submitButtonText : null}>
                    {showLabel ? submitButtonText : ''}
                </PromptButton>
            ) : null}
            {dialog}
        </Loader>
    );
};
